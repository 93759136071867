import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import { mutations } from '../lib/state'
import { PageProps } from './_app'
import { Grid, Container, LoginForm } from '@persol-epdndo/design-systems'
import { ServiceDeskEmail } from '@persol-epdndo/base-shared'
import CommonMeta from '../components/CommonMeta'
import * as Sentry from '@sentry/nextjs'

const captureSentryLoginFail = (tags: { [key: string]: string }) => {
  Sentry.withScope(scope => {
    // ExtractにするとFilterされて内容が見えないためTagにしている
    scope.setTags(tags)
    Sentry.captureMessage('ログイン失敗')
  })
}

export const Login: FC<PageProps> = (props): JSX.Element => {
  const router = useRouter()
  const { method } = router.query
  const { enqueueSnackbar } = useSnackbar()

  return (
    <>
      <CommonMeta title='ログイン' />
      <Container maxWidth='xs'>
        <Grid container spacing={3} alignItems='center' justify='center'>
          <Grid item>
            <LoginForm
              logoComponent={<img src='/persol_login_logo.svg' />}
              onADLogin={async v => {
                try {
                  await mutations.signInWithAd(v.accountId, v.password)
                } catch (error: any) {
                  if (
                    error.code === 'UserLambdaValidationException' &&
                    error.message === 'DefineAuthChallenge failed with error loginAttemptExceeded.'
                  ) {
                    enqueueSnackbar('アカウントがロックされています。しばらく経ってから再度ログインしてください。')
                  } else {
                    // NOTE: ログイン試行制限以外にも、エラーが発生するケースとしてネットワークエラーなども考えられるが、あまり多くはないと思うので一旦統一のエラーメッセージを表示する。
                    enqueueSnackbar('ログインに失敗しました。IDとパスワードを確認してください。')
                  }
                  captureSentryLoginFail({
                    accountId: v.accountId,
                    name: error.name,
                    code: error.code,
                    message: error.message,
                  })
                }
              }}
              onMailLogin={async v => {
                try {
                  await mutations.signIn(v.email, v.password)
                } catch (error: any) {
                  if (
                    error.code === 'UserLambdaValidationException' &&
                    error.message === 'PreAuthentication failed with error loginAttemptExceeded.'
                  ) {
                    enqueueSnackbar('アカウントがロックされています。しばらく経ってから再度ログインしてください。')
                  } else {
                    enqueueSnackbar('ログインに失敗しました。IDとパスワードを確認してください。')
                  }
                  captureSentryLoginFail({
                    email: v.email,
                    name: error.name,
                    code: error.code,
                    message: error.message,
                  })
                }
              }}
              onForgotPasswordLinkClick={async () => await router.push('/password')}
              onCreateAccountLinkClick={async () => await router.push('/createAccount')}
              onServiceDeskLinkClick={() => {
                document.location.href = `mailto:${ServiceDeskEmail}`
              }}
              method={method === 'email' ? 'email' : 'ad'}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

Login.defaultProps = {
  authProps: {
    guestOnly: true,
  },
}

export default Login
